import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Custom route guard function
function ProtectedRoute({ user, children }) {
  ProtectedRoute.propTypes = {
    user: PropTypes.object,
    children: PropTypes.element,
  };
  if (!user) {
    return <Navigate to={'/login'} replace />;
  }
  return children;
}

export default ProtectedRoute;
