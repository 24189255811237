const { api, endpoints } = require('./apiConfig');

const users = async () => {
  try {
    const { data } = await api.get(endpoints.users);
    return data.users;
  } catch (error) {
    if (error.response && error.response.status !== 401) {
      throw error;
    }
  }
  return [];
};

export default users;
