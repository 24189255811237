import { api, endpoints } from './apiConfig';

const fetchCategory = async () => {
  try {
    const { data } = await api.get(endpoints.categories);
    return data;
  } catch (error) {
    return [];
  }
};

export default fetchCategory;
