import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/Product/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import EditProductPage from './pages/Product/EditProductPage';
import CreateProductPage from './pages/Product/CreateProductPage';
import OrdersPage from './pages/OrdersPage';
import CategoriesPage from './pages/Category/CategoriesPage';
import CreateCategoryPage from './pages/Category/CreateCategoryPage';
import EditCategoryPage from './pages/Category/EditCategoriesPage';
import ProtectedRoute from './hooks/protectedRoutes';
import { getCookie } from './utils/userDetails';

// ----------------------------------------------------------------------

export default function Router() {
  const userCookie = getCookie('user');
  const parsedCookie = JSON.parse(userCookie);

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute user={parsedCookie}>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'products/:productId', element: <EditProductPage /> },
        { path: 'products/new', element: <CreateProductPage /> },
        { path: 'orders', element: <OrdersPage /> },
        { path: 'categories/new', element: <CreateCategoryPage /> },
        { path: 'categories', element: <CategoriesPage /> },
        { path: 'categories/:categoryId', element: <EditCategoryPage /> },
        { path: 'orders/status/:statusId', element: <EditCategoryPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },

    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
