import { api, endpoints } from './apiConfig';

const fetchOrder = async () => {
  try {
    const { data } = await api.get(endpoints.allOrders);
    return data;
  } catch (error) {
    return [];
  }
};

export default fetchOrder;
