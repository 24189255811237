import { setCookie } from './cookie';

const { api, endpoints } = require('./apiConfig');

const login = async ({ email, password }) => {
  await api
    .post(endpoints.login, { email, password })
    .then(({ data }) => {
      const userJSON = JSON.stringify(data.user);
      const authToken = data.token;

      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 10);

      setCookie('user', userJSON, expiryDate);
      setCookie('x-auth-token', authToken, expiryDate);
      return data;
    })
    .catch((error) => {
      if (error.response && error.response.status) {
        if (typeof error.response.data === 'string') {
          throw new Error(error.message);
        }
        throw new Error(error.response.data.details);
      }
      throw new Error(error.message);
    });
};

export { login };
