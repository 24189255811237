import React from 'react';
import { TextField, IconButton, Grid, Box } from '@mui/material';
import { MdAttachMoney, MdTextFormat } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import PropTypes from 'prop-types';

const FoodOptions = ({ index, data, handleInputChange, handleDeleteComponent }) => {
  FoodOptions.propTypes = {
    index: PropTypes.number.isRequired,
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleDeleteComponent: PropTypes.func.isRequired,
  };
  const { name, price } = data;

  const handleDeleteClick = () => {
    handleDeleteComponent(index);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Box display="flex" alignItems="center">
          <MdTextFormat className="text-gray-700 text-2xl" />
          <TextField
            fullWidth
            label="Option Name"
            variant="outlined"
            value={name}
            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            required
            placeholder="Large Tray"
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box display="flex" alignItems="center">
          <MdAttachMoney style={{ fontSize: '24px', color: '#666' }} />
          <TextField
            fullWidth
            type="number"
            label="Price"
            variant="outlined"
            value={price}
            onChange={(e) => handleInputChange(index, 'price', e.target.value)}
            required
            placeholder="Price"
          />
          <IconButton onClick={handleDeleteClick} color="error">
            <RiDeleteBinLine />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FoodOptions;
