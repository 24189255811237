function setCookie(name, value, days) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);

  const cookieValue = `${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}`;

  document.cookie = `${name}=${cookieValue}; path=/`;
}

function deleteCookie(cookieName) {
  // Delete a cookie by setting its expiration date to the past
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return null;
};

export { deleteCookie };
export { setCookie };
export { getCookie };
