import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Collapse,
  Select,
} from '@mui/material';
import { toast } from 'react-toastify';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import orders from '../utils/fetchOrders';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import OrderItemCard from '../components/orders/orderItem';
import orderStatuses from '../utils/orderStatuses';
import { api, endpoints } from '../utils/apiConfig';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Order Id', alignRight: false },
  { id: 'customer', label: 'Customer', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'items', label: 'Items', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'paid', label: 'Payment Status', alignRight: false },
  { id: 'paymentDetails', label: 'Amount Paid', alignRight: false },
  { id: '_', label: '', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'paymentDetails') {
    const amountA = a.paymentDetails.amount || 0;
    const amountB = b.paymentDetails.amount || 0;
    return amountB - amountA; // descending order
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const formateDate = (dateString) => {
  const dateObject = new Date(dateString);

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateObject.toLocaleDateString(undefined, options);

  return formattedDate;
};

const formatTime = (dateString) => {
  const dateObject = new Date(dateString);
  const formattedTime = dateObject.toLocaleTimeString();
  return formattedTime;
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const formatOrderItems = (order) => {
  const qty = order.map((item) => item.qty);

  const total = qty.reduce((prev, current) => prev + current, 0);

  return total;
};

const formatOrderTotalPrice = (order) => {
  const prices = order.map((item) => item.qty * item.selectedOption.price);

  const total = prices.reduce((prev, current) => prev + current, 0);

  return total;
};

const formatUserDetails = (details) => `${sentenceCase(details.firstname)} ${details.lastname}`;

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (order) => {
      const fullDetails = `${formatUserDetails(order.userId)} ${order._id} ${formatOrderItems(order.items)}
        ${formateDate(order.createdAt)} ${formatTime(order.createdAt)} ${order.userId.email} ${order.userId.phoneNumber}
      `.toLowerCase();
      return fullDetails.includes(query.toLowerCase());
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [rowOpen, setRowOpen] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState({});

  const [statuses, setStatuses] = useState([]);

  const [ORDERS, setORDERS] = useState([]);

  async function allOrders() {
    const ordersList = await orders();
    setORDERS(ordersList);

    return [];
  }

  useEffect(() => {
    setStatuses(orderStatuses);
    toast
      .promise(allOrders(), {
        pending: 'Fetching Orders...',
        error: 'Error fetching orders, Please try again🤗',
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const showToastFailure = (message) => {
    toast.error(message, { autoClose: 4000 });
  };

  const handleStatusChange = async (orderId, newStatus) => {
    setSelectedStatus((prevSelectedStatus) => ({
      ...prevSelectedStatus,
      [orderId]: newStatus,
    }));

    try {
      await api.put(`${endpoints.orders}/${orderId}`, { status: newStatus });
      toast.success('Updated Order Details');
    } catch (error) {
      console.log(error);
      if (error.response) {
        showToastFailure(error.response.data.details);
        return;
      }
      showToastFailure(error.message);
    }
  };

  const handleProductsMenu = (orderId) => {
    const isOpen = rowOpen.find((item) => item.orderId === orderId)?.isOpen === true;

    setRowOpen((prevSelectedStatus) => {
      // Filter out existing objects with the same orderId
      const filteredStatus = prevSelectedStatus.filter((item) => item.orderId !== orderId);

      // Add the new object
      return [...filteredStatus, { orderId, isOpen: !isOpen }];
    });
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ORDERS.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ORDERS.length) : 0;

  const filteredUsers = applySortFilter(ORDERS, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Orders | Josh Kitchen </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={ORDERS.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { userId, createdAt, _id, items, paymentDetails, status } = row;

                    const selectedUser = selected.indexOf(_id) !== -1;

                    return (
                      <>
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {_id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                            {formatUserDetails(userId)}
                            <p style={{ whiteSpace: 'nowrap', fontSize: '13px' }}>
                              {userId.phoneNumber || userId.email}
                            </p>
                          </TableCell>
                          <TableCell align="left">
                            <p style={{ whiteSpace: 'nowrap' }}>{formateDate(createdAt)}</p>
                            <p style={{ whiteSpace: 'nowrap', fontSize: '13px' }}>{formatTime(createdAt)}</p>
                          </TableCell>
                          <TableCell align="left">{formatOrderItems(items)}</TableCell>
                          <TableCell align="left">${formatOrderTotalPrice(items)}</TableCell>
                          <TableCell>
                            <Select
                              key={_id}
                              value={selectedStatus[_id] || status}
                              onChange={(e) => {
                                const newStatus = e.target.value;
                                handleStatusChange(_id, newStatus);
                              }}
                            >
                              {statuses.length > 0 &&
                                statuses.map((status, index) => (
                                  <MenuItem key={index} value={status.name}>
                                    {status.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </TableCell>
                          <TableCell align="left">{paymentDetails.status}</TableCell>
                          <TableCell align="left">
                            {paymentDetails.amount !== undefined ? `$${paymentDetails.amount}` : 'NULL'}
                          </TableCell>

                          <TableCell align="left">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                handleProductsMenu(_id);
                              }}
                            >
                              {rowOpen.find((item) => item.orderId === _id)?.isOpen === true ? (
                                <MdArrowUpward />
                              ) : (
                                <MdArrowDownward />
                              )}
                            </IconButton>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={TABLE_HEAD.length + 2}>
                            <Collapse
                              timeout="auto"
                              in={rowOpen.find((item) => item.orderId === _id)?.isOpen === true}
                              unmountOnExit
                            >
                              {items.length > 0 &&
                                items.map((row, index) => <OrderItemCard key={index} product={row} />)}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={ORDERS.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
