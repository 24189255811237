import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Container, Stack, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
// components
import { ProductSort, ProductList } from '../../sections/@dashboard/products';
// mock
import products from '../../utils/productsList';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

async function fetchProducts() {
  const productsList = await products();
  return productsList;
}

export default function ProductsPage() {
  const [PRODUCTS, setProducts] = useState([]);

  const loadProducts = async () => {
    const PRODUCTS = await fetchProducts();
    setProducts(PRODUCTS);
  };

  useEffect(() => {
    toast.promise(loadProducts(), { pending: 'Loading Products', error: 'Error loading products, Please try again🤗' });
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Josh's Kitchen </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Link to={'new'}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Product
            </Button>
          </Link>
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList loadProducts={loadProducts} products={PRODUCTS} />
      </Container>
    </>
  );
}
