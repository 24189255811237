import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const OrderItems = ({ product }) => {
  OrderItems.propTypes = {
    product: PropTypes.shape({
      food: PropTypes.any,
      selectedOption: PropTypes.object,
      qty: PropTypes.number,
    }),
  };

  const cardStyle = {
    padding: '16px',
    margin: '16px',
    minWidth: '700px', // Minimum width for the card
  };

  return (
    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    //   <div style={{ maxWidth: '500px', overflow: 'auto', border: '1px solid #ccc' }}>
    <Paper elevation={3} style={cardStyle}>
      <Grid container alignItems="center" spacing={2}>
        {/* Product Image */}
        <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <img src={product.food.imageUrl} alt="{product.name}" width="70px" />
          <div>
            <Typography variant="body1">{product.food.name}</Typography>
            <Typography variant="body2">{product.selectedOption.name}</Typography>
          </div>
        </Grid>

        {/* Price and Quantity */}
        <Grid
          item
          xs={8}
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{
            gap: '80px',
            alignContent: 'center',
          }}
        >
          <Typography variant="body2">x{product.qty}</Typography>
          <Typography variant="h8">${product.selectedOption.price}</Typography>
        </Grid>
      </Grid>
    </Paper>
    //   </div>
    // </div>
  );
};

export default OrderItems;
