import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';

const BackBtn = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current location matches "/app"
  const isAppRoute = location.pathname !== '/dashboard/app';

  // If the location matches "/app", render the BackBtn; otherwise, return null
  return isAppRoute ? (
    <MdArrowBack
      size={35}
      onClick={() => {
        navigate(-1);
      }}
    />
  ) : null;
};

export default BackBtn;
