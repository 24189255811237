import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';

import { MdFastfood, MdDescription, MdAttachMoney, MdCloudUpload, MdDelete } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TextareaAutosize, Input, IconButton, Typography, Paper, Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

import { api, endpoints } from '../../utils/apiConfig';
import { FoodOptions } from '../../sections/@dashboard/products';
import { Loader } from '../../components/Loader';

const EditContainer = () => {
  const location = useLocation();
  const { _id, name, category, imageUrl, description, options } = location.state;
  const [productTitle, setTitle] = useState(name);
  const [productCategories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productImageUrl, setImageUrl] = useState(imageUrl);
  const [imageAsset, setImageAssest] = useState(null);
  const [productDescription, setDescription] = useState(description);
  const [componentData, setComponentData] = useState(options);
  const [available, setAvailable] = useState(true);
  const [productSelectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();

  const formatCategories = (categories) =>
    categories.map((item) => ({
      label: item.name,
      value: item.name,
      id: item._id,
    }));

  const showToastMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
    });
  };

  const showToastFailure = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 4000,
    });
  };

  const handleClick = () => {
    if (validateComponents()) {
      setComponentData((prevData) => [...prevData, { name: '', price: 0 }]);
    } else {
      showToastFailure('Please fill in the previous Option before adding another one.');
    }
  };

  const handleInputChange = (index, field, value) => {
    setComponentData((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value;
      return newData;
    });
  };

  const handleDeleteComponent = (index) => {
    setComponentData((prevData) => {
      const newData = prevData.filter((_, itemIndex) => itemIndex !== index);
      return newData;
    });
  };

  const validateComponents = () => {
    for (let i = 0; i < componentData.length; i += 1) {
      const { name, price } = componentData[i];
      if (!name || !price) {
        return false;
      }
    }
    return true;
  };

  const uploadImage = (e) => {
    setIsLoading(true);
    const image = e.target.files[0];
    setImageAssest(image);
    if (productImageUrl) setImageUrl(null);
    setIsLoading(false);
  };

  const fetchCategories = async () => {
    api.get(endpoints.categories).then((response) => {
      const { categories } = response.data;
      setCategories(formatCategories(categories));
    });
  };

  useEffect(() => {
    fetchCategories();
    const formmattedCategories = formatCategories(category);
    setSelectedCategories(formmattedCategories);

    // eslint-disable-next-line
  }, [category]);

  const deleteImage = () => {
    if (productImageUrl) {
      setImageUrl(null);
    } else {
      setImageAssest(null);
    }
  };

  const uploadDetails = async () => {
    const formData = new FormData();
    formData.append('name', productTitle);
    formData.append('_id', _id);
    formData.append('description', productDescription);
    formData.append('image', productImageUrl || imageAsset);
    formData.append('options', JSON.stringify([...componentData]));
    const categories = productSelectedCategories.map((category) => category.id); // database expects an array of strings
    formData.append('category', JSON.stringify(categories));
    formData.append('available', available);

    const response = await api.put(endpoints.products, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };

  const clearData = () => {
    setImageUrl(null);
    setDescription('');
    setTitle('');
    setSelectedCategories([]);
  };

  const saveDetails = async () => {
    if (
      !productTitle ||
      !(productImageUrl || imageAsset) ||
      !componentData.length > 0 ||
      !productSelectedCategories.length > 0 ||
      !productDescription
    ) {
      showToastFailure('Required Fields Must Be Filled');
      return;
    }

    setIsLoading(true);
    await uploadDetails()
      .then(() => {
        clearData();
        showToastMessage('Successfully Uploaded Product!');
        setIsLoading(false);
        navigate('/dashboard/products', { replace: true });
      })
      .catch((error) => {
        console.log(error);
        showToastFailure(error.response.data.details);
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        width: '100%',
        marginTop: '12px',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        style={{
          width: '90%',
          maxWidth: '75%',
          border: '1px solid #ccc',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <div
          style={{
            width: '100%',
            paddingBottom: '16px',
            borderBottom: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <MdFastfood style={{ fontSize: '24px', color: '#666' }} />
          <Input
            type="text"
            style={{
              width: '100%',
              fontSize: '16px',
              backgroundColor: 'transparent',
              border: 'none',
              borderBottom: '1px solid #ccc',
              color: '#333',
            }}
            value={productTitle}
            name="item-name"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Food/Item name"
            required
          />
        </div>
        <div style={{ width: '100%' }}>
          {
            <MultiSelect
              options={productCategories}
              value={productSelectedCategories}
              onChange={setSelectedCategories}
              labelledBy="Select"
              styles={{ option: { fontSize: '16px' } }}
            />
          }
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            border: '2px dashed #ccc',
            borderRadius: '8px',
            padding: '16px',
            cursor: 'pointer',
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!imageAsset && !productImageUrl ? (
                <>
                  <label
                    htmlFor="uploadImage"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                      <MdCloudUpload style={{ fontSize: '24px', color: '#666' }} />
                      <Typography style={{ fontSize: '16px', color: '#666', cursor: 'pointer' }}>
                        Click here to upload
                      </Typography>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      name="uploadImage"
                      id="uploadImage"
                      style={{ width: '0', height: '0' }}
                      onChange={uploadImage}
                    />
                  </label>
                </>
              ) : (
                <>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={(imageAsset && URL.createObjectURL(imageAsset)) || productImageUrl}
                      alt="uploaded"
                      style={{ width: '100%', height: '300px', objectFit: 'cover' }}
                    />
                    <IconButton
                      type="button"
                      style={{
                        position: 'absolute',
                        bottom: '12px',
                        right: '12px',
                        padding: '12px',
                        borderRadius: '50%',
                        backgroundColor: '#f00',
                        fontSize: '24px',
                        color: '#fff',
                        cursor: 'pointer',
                      }}
                      onClick={deleteImage}
                    >
                      <MdDelete />
                    </IconButton>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
          <div
            style={{
              width: '100%',
              paddingBottom: '16px',
              borderBottom: '1px solid #ccc',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <MdDescription style={{ fontSize: '24px', color: '#666' }} />
            <TextareaAutosize
              value={productDescription}
              onChange={(e) => setDescription(e.target.value)}
              style={{
                width: '100%',
                fontSize: '16px',
                backgroundColor: 'transparent',
                border: 'none',
                borderBottom: '1px solid #ccc',
                color: '#333',
              }}
              required
              name="description"
              placeholder="Enjoy this ravishing food with ..."
            />
          </div>
          <div
            style={{
              width: '100%',
              paddingBottom: '16px',
              borderBottom: '1px solid #ccc',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <MdAttachMoney style={{ fontSize: '24px', color: '#666' }} />
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
                <Checkbox
                  checked={available}
                  onChange={() => {
                    setAvailable(!available);
                  }}
                />
                <Typography variant="subtitle2" noWrap>
                  Available
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          {componentData.map((data, index) => (
            <FoodOptions
              key={index}
              index={index}
              data={data}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
            />
          ))}
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{
            width: '100%',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f00',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          Add an Option For This Food
        </Button>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="success"
            style={{
              marginLeft: '0',
              marginRight: 'auto',
              width: '100%',
              height: '40px',
              display: 'grid',
              alignItems: 'center',
              justifyItems: 'center',
              backgroundColor: '#0f0',
              cursor: 'pointer',
            }}
            onClick={saveDetails}
          >
            Save
          </LoadingButton>
        </div>
      </Paper>
    </div>
  );
};

export default EditContainer;
