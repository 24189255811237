import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// @mui
import {
  Card,
  Stack,
  Divider,
  Popover,
  Checkbox,
  MenuItem,
  IconButton,
  CardHeader,
  FormControlLabel,
  Input,
} from '@mui/material';
import { MdCheck, MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
// components
import Iconify from '../../../components/iconify';
import { api, endpoints } from '../../../utils/apiConfig';

// ----------------------------------------------------------------------

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppTasks({ title, subheader, ...other }) {
  const [newTask, setNewTask] = useState('');
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isEditingTask, setIsEdidtingTask] = useState(false);
  const [editingTaskValue, setEditingTaskValue] = useState({});
  const [tasksList, setTaskList] = useState([]);

  const { control } = useForm({
    defaultValues: {
      taskCompleted: [],
    },
  });

  const postTaskData = async (taskData) => {
    await api.post(endpoints.tasks, taskData).then(({ data }) => {
      setTaskList([data, ...tasksList]);
    });
  };

  const fetchTasks = async () => {
    toast
      .promise(api.get(endpoints.tasks), {
        pending: 'Fetching Tasks',
        error: 'Error fetching task, Please reload',
      })
      .then(({ data }) => {
        setTaskList(data);
      })
      .catch(() => {
        toast.error('Unable to fetch task from the server');
      });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const closeInput = () => {
    setNewTask('');
    setIsEdidtingTask(false);
    setIsAddingTask(false);
  };

  const handleAddTask = async () => {
    if (newTask.trim() !== '') {
      if (isEditingTask) {
        const tasks = tasksList.filter((task) => task._id !== editingTaskValue._id);
        const taskData = { label: newTask };

        toast
          .promise(api.put(`${endpoints.tasks}/${editingTaskValue._id}`, taskData), {
            pending: 'Adding Task',
            error: 'Error adding task, Please try again🤗',
          })
          .then(({ data }) => {
            setTaskList([data, ...tasks]);
          })
          .catch((error) => {
            toast.error(error.message);
          });
        closeInput();
        return;
      }

      const taskToAdd = { label: newTask };

      toast.promise(postTaskData(taskToAdd), {
        pending: 'Adding Task',
        error: 'Error adding task, Please try again🤗',
      });

      closeInput();
    }
  };

  const markAsCompleted = async (taskId) => {
    const task = tasksList.find((task) => task._id === taskId);
    const filteredTasks = tasksList.filter((task) => task._id !== taskId);

    toast
      .promise(api.put(`${endpoints.tasks}/${taskId}`, { label: task.label, completed: !task.completed }), {
        pending: 'Marking As Copmplete...',
        error: 'Error marking as complete, Please try again🤗',
      })
      .then(({ data }) => {
        setTaskList([data, ...filteredTasks]);
      })
      .catch((err) => toast.error(err.message));
  };

  const onDeleteTask = async (taskId) => {
    const filteredTasks = tasksList.filter((task) => task._id !== taskId);
    setTaskList(filteredTasks);

    toast.promise(api.delete(`${endpoints.tasks}/${taskId}`), {
      pending: 'Deleting Task',
      error: 'Unable to delete task from the server Please try again🤗',
    });
  };

  const handleEditTask = (taskId) => {
    const task = tasksList.find((task) => task._id === taskId);
    if (task) {
      setNewTask(task.label);
      setIsAddingTask(true);
      setEditingTaskValue(task);
      setIsEdidtingTask(true);
    }
  };

  return (
    <Card {...other}>
      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 10 }}>
        <CardHeader title={title} subheader={subheader} />
        <IconButton
          onClick={() => {
            setIsAddingTask(!isAddingTask);
          }}
          size="large"
          color="inherit"
          sx={{
            opacity: 0.48,
            marginTop: 3,
          }}
        >
          <Iconify icon={'eva:plus-fill'} />
        </IconButton>
      </div>

      <Controller
        name="taskCompleted"
        control={control}
        render={() => (
          <>
            {isAddingTask && (
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: 3 }}>
                <Input
                  type="text"
                  style={{
                    width: '70%',
                    fontSize: '16px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: 'none',
                    color: '#333',
                    maxHeight: '35px',
                    marginLeft: 22,
                    marginTop: 20,
                  }}
                  name="item-name"
                  value={newTask}
                  onChange={(e) => {
                    setNewTask(e.target.value);
                  }}
                  placeholder="Task Name"
                  required
                />
                <div style={{ display: 'flex' }}>
                  {isEditingTask && (
                    <IconButton
                      onClick={() => closeInput()}
                      size="large"
                      color="inherit"
                      sx={{
                        opacity: 0.48,
                        marginTop: 3,
                      }}
                    >
                      <MdClose style={{ color: 'red' }} />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => handleAddTask()}
                    size="large"
                    color="inherit"
                    sx={{
                      opacity: 0.48,
                      marginTop: 3,
                    }}
                  >
                    <MdCheck style={{ color: '#006400' }} />
                  </IconButton>
                </div>
              </div>
            )}

            {tasksList.map((task) => (
              <TaskItem
                key={task._id}
                task={task}
                onDelete={onDeleteTask}
                onEdit={handleEditTask}
                checked={task.completed}
                markAsCompleted={markAsCompleted}
                onChange={() => {
                  markAsCompleted(task._id);
                  // field.onChange(onSelected(task._id));
                }}
              />
            ))}
          </>
        )}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  task: PropTypes.shape({
    _id: PropTypes.string,
    label: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  markAsCompleted: PropTypes.func,
};

function TaskItem({ task, checked, onChange, onDelete, onEdit, markAsCompleted }) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleMarkComplete = () => {
    handleCloseMenu();
    markAsCompleted(task._id);
  };

  const handleEdit = () => {
    handleCloseMenu();
    onEdit(task._id);
  };

  const handleDelete = () => {
    handleCloseMenu();
    onDelete(task._id);
  };

  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 0.75,
        ...(checked && {
          color: 'text.disabled',
          textDecoration: 'line-through',
        }),
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} />}
        label={task.label}
        sx={{ flexGrow: 1, m: 0 }}
      />

      <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={handleOpenMenu}>
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleMarkComplete}>
          <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
          {(!checked && 'Mark Complete') || 'UnMark As Complete'}
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </Stack>
  );
}
