const orderStatuses = [
  {
    id: 1,
    name: 'Pending',
    description: 'The order has been received and is being processed.',
  },
  {
    id: 2,
    name: 'Order Received',
    description: 'The order has been received and is being processed.',
  },
  {
    id: 3,
    name: 'Order Confirmed',
    description: 'The order has been confirmed, and preparation is underway.',
  },
  {
    id: 4,
    name: 'Preparing',
    description: 'The food is being prepared for pickup.',
  },
  {
    id: 5,
    name: 'Ready for Pickup',
    description: 'The order is ready for pickup at our location.',
  },
  {
    id: 6,
    name: 'Cancelled',
    description: 'The order has been canceled or cannot be fulfilled.',
  },
  {
    id: 7,
    name: 'Delayed',
    description: 'There is a delay in preparing the order. We apologize for the inconvenience.',
  },
  {
    id: 8,
    name: 'Pending Payment',
    description: 'We are awaiting payment confirmation before processing the order.',
  },
  {
    id: 9,
    name: 'Payment Failed',
    description: 'There was an issue with processing the payment. Please check your payment details.',
  },
  {
    id: 10,
    name: 'Refunded',
    description: 'A refund has been issued for the order.',
  },
];

export default orderStatuses;
