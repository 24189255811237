function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split('; ');
  let foundCookieValue = null;

  cookies.forEach((cookie) => {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      foundCookieValue = decodeURIComponent(cookieValue);
    }
  });

  return foundCookieValue;
}

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export { getCookie };
export { deleteCookie };
