import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// sections
import { AppTasks, AppOrderSummary, AppWidgetSummary } from '../sections/@dashboard/app';
import { getCookie } from '../utils/userDetails';
import { api, endpoints } from '../utils/apiConfig';

// ----------------------------------------------------------------------

const formatOrderSummary = (summary) => summary.map((item) => ({ label: item._id, value: item.count }));

export default function DashboardAppPage() {
  const theme = useTheme();
  const [userDetails, setUserDetails] = useState({});
  const [ordersCount, setOrdersCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [foodCount, setFoodCount] = useState(0);
  const [categoriesCount, setCategoriesCount] = useState(0);
  const [orderSummary, setOrderSummary] = useState([]);

  const fetchDashBoardData = async () => {
    try {
      const { data } = await api.get(endpoints.dashboard);
      const { totalOrders, users, food, categories, orderSummary } = data;
      setOrdersCount(totalOrders);
      setUsersCount(users);
      setFoodCount(food);
      setCategoriesCount(categories);
      const result = formatOrderSummary(orderSummary);
      setOrderSummary(result);
    } catch (err) {
      console.log(err);
      toast.error(err.message || (err.response && err.response.data.details));
    }
  };

  useEffect(() => {
    const userJsonString = getCookie('user');

    const user = JSON.parse(userJsonString);

    fetchDashBoardData();
    setUserDetails(user);
  }, []);

  return (
    <>
      <Helmet>
        <title> Josh Kitchen </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }} style={{ textTransform: 'capitalize' }}>
          Hi, {userDetails?.firstname || 'Admin'}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Categories" total={categoriesCount} icon={'ant-design:folder-outline'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Users" total={usersCount} color="info" icon={'ant-design:user-outline'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Item Orders"
              total={ordersCount}
              color="warning"
              icon={'ant-design:shopping-cart-outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Products"
              total={foodCount}
              color="warning"
              icon={'ant-design:shopping-outline'}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderSummary
              title="Order Summary"
              chartData={orderSummary}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks title="Tasks" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
