import axios from 'axios';
import { getCookie } from './cookie';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    // Fetch the token from cookies
    const token = getCookie('x-auth-token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const endpoints = {
  products: `/products`,
  productsList: `/products/all`,
  categories: '/category',
  signin: '/signup',
  login: '/login/admin',
  logout: '/user/logout',
  users: '/user',
  allOrders: '/orders/all',
  orders: '/orders',
  status: '/status',
  dashboard: '/dashboard',
  tasks: '/tasks',
};

export { api, endpoints };
