import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
// components
import { login } from '../../../utils/auth';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    if (email.length === 0 || password.length === 0) {
      setErrors(true);
      setErrorMsg('Email and password required');
      setIsLoading(false);
      return;
    }

    try {
      await login({ email, password });
      navigate('/dashboard', { replace: true });
    } catch (error) {
      setErrors(true);
      setErrorMsg(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        {errors && (
          <Alert
            onClose={() => {
              setErrors(!errors);
            }}
            severity="error"
          >
            {errorMsg}
          </Alert>
        )}
        <TextField name="email" value={email} onChange={(e) => setEmail(e.target.value)} label="Email address" />
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" loading={isLoading} variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
