import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
// @mui
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button } from '@mui/material';
import { useState } from 'react';

import ShopProductCard from './ProductCard';
import { api, endpoints } from '../../../utils/apiConfig';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  loadProducts: PropTypes.any,
};

export default function ProductList({ products, loadProducts, ...other }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState('');

  const showModal = (value, item) => {
    setModalOpen(value);
    setItemToDelete(item);
  };

  const showToastError = (message) => {
    toast.error(message, { autoClose: 4000 });
  };

  const deleteItem = async () => {
    try {
      await api.delete(`${endpoints.products}/${itemToDelete}`);
      setModalOpen(false);
      toast.success('Successfuly Deleted', { autoClose: 4000 });
      loadProducts();
    } catch (error) {
      showToastError(error.message);
    }
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p style={{ color: 'red' }}>Are you sure you want to delete this item?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={deleteItem} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} {...other}>
        {products.map((product) => (
          <Grid key={product._id} item xs={12} sm={6} md={3}>
            <ShopProductCard showModal={showModal} product={product} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
