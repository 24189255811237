import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Button, Grid, Paper, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { api, endpoints } from '../../utils/apiConfig';

const EditCategoryPage = () => {
  const location = useLocation();
  const { name, _id } = location.state;

  const navigate = useNavigate();

  const [categoryName, setName] = useState(name);

  const showToastFailure = (message) => {
    toast.error(message, { autoClose: 4000 });
  };

  const showToastSuccess = (message) => {
    toast.success(message, { autoClose: 4000 });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const createCategory = async () => {
    if (!categoryName) {
      showToastFailure('Please Give the Category A Name');
      return;
    }

    try {
      await api.put(`${endpoints.categories}/${_id}`, { name: categoryName });
      showToastSuccess('Successfully Updated Category');
      navigate('/dashboard/categories', { replace: true });
    } catch (error) {
      showToastFailure(error.message);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item xs={12} sm={6} md={4}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6" gutterBottom>
            Edit The Name of The Category
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            value={categoryName}
            onChange={handleNameChange}
            margin="normal"
          />
          <Button onClick={createCategory} type="submit" variant="contained" color="primary" fullWidth size="large">
            Submit
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditCategoryPage;
